import Order from 'shared/types/order';
import {
  yupArray,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import {
  array,
  ArraySchema,
  NumberSchema,
  object,
  string,
  StringSchema,
} from 'yup';
import { isSuperpayPayment } from '../../../core/PaymentUtils';
import {
  isOrderPaymentFlags,
  OrderPaymentFlagDTO,
} from '../../data/dto/OrderPaymentFlagsDTO';
import { calculateVehiclesSum } from './helpers/orderFormHelpers';
import { OrderFormValues } from './OrderForm';

export const emailValidationSchema = string()
  .email(
    "Invalid email address. Valid email can contain only latin letters, numbers, '@' and '.'",
  )
  .nullable();

interface PriceSuperPayValidProps {
  order?: Order | OrderFormValues;
  orderPaymentFlags?: OrderPaymentFlagDTO;
  value?: number | null;
}

export function isPriceSuperPayValid({
  order,
  orderPaymentFlags,
  value,
}: PriceSuperPayValidProps) {
  if (!isSuperpayPayment(order?.payment)) {
    return true;
  }

  return (
    !order?.status ||
    order.status === 'new' ||
    order.status === 'canceled' ||
    (value && value > 0) ||
    (isOrderPaymentFlags(orderPaymentFlags) && orderPaymentFlags.can_edit)
  );
}

export const OrderFormSchema = yupObject({
  number: string()
    .required('Fill out this field')
    .max(50, 'Order ID must be at most 50 characters long')
    .trim('Fill out this field'),
  price: yupNumber().when(
    '$orderPaymentFlags',
    (orderPaymentFlags: OrderPaymentFlagDTO, schema: NumberSchema) => {
      return schema
        .test(
          'isPriceMatch',
          "Price per vehicle doesn't match Total Price.",
          (value, context) => {
            const vehiclesPriceSum = calculateVehiclesSum(
              context.parent.vehicles,
              'price',
            );
            return !vehiclesPriceSum || vehiclesPriceSum === Number(value);
          },
        )
        .test(
          'isPriceSuperPayValid',
          'Price should be above $0 for SuperPay',
          (value, context) => {
            const order = (
              context as unknown as { from: Array<{ value: OrderFormValues }> }
            ).from[0]?.value;
            return isPriceSuperPayValid({ order, orderPaymentFlags, value });
          },
        );
    },
  ),
  customer_payment: yupObject({
    tariff: yupNumber().test(
      'isTariffMatch',
      "Tariff per vehicle doesn't match Total Tariff.",
      (value, context) => {
        // TODO: https://superdispatch.atlassian.net/browse/STMS-2148
        const vehicles = (
          context as unknown as { from: Array<{ value: OrderFormValues }> }
        ).from[1]?.value.vehicles;
        const vehiclesTariffSum = calculateVehiclesSum(vehicles, 'tariff');
        return !vehiclesTariffSum || vehiclesTariffSum === Number(value);
      },
    ),
    terms: string().nullable(),
  }),
  loadboard_instructions: string().nullable(),
  tags: yupArray(yupNumber()).when(
    '$maxNumberOfTags',
    (maxNumberOfTags, schema: ArraySchema<number>) => {
      return schema.max(
        maxNumberOfTags,
        `Maximum amount of tags is ${maxNumberOfTags as number}`,
      );
    },
  ),
  vehicles: array(),
  customer: object({
    email: emailValidationSchema,
    contact: object({
      email: emailValidationSchema,
    }),
  }),
  payment: yupObject({
    terms: string().nullable(),
    method: string()
      .nullable()
      .when(
        ['$isSuperPaySuspend', '$formType'],
        (
          isSuperPaySuspend: boolean,
          formType: string,
          schema: StringSchema,
        ) => {
          return schema.test(
            'isMethodSuperPayValid',
            'SuperPay not available. Change payment method to proceed.',
            (value) =>
              formType === 'save' || value !== 'superpay' || !isSuperPaySuspend,
          );
        },
      ),
  }),
  pickup: yupObject({
    venue: yupObject({
      contact: yupObject({
        email: emailValidationSchema,
      }),
    }),
    scheduled_at: yupString()
      .nullable()
      .when('$formType', (formType, schema: StringSchema) => {
        return [
          'save_and_send_offer',
          'save_and_post_to_cd',
          'save_and_post_to_sdlb',
          'save_and_post_to_all',
        ].includes(formType)
          ? schema.required('Carrier Pickup date is required')
          : schema;
      }),
  }),
  delivery: yupObject({
    venue: yupObject({
      contact: yupObject({
        email: emailValidationSchema,
      }),
    }),
  }),
});
